.container {
	padding: 14px;
	box-sizing: border-box;
	width: 240px;
}

.textboxNumber {
	width: 49%;
}

.buyBtn {
	width: 100%;
}

@media screen and (max-width: 600px) {
	.container {
		width: 100%;
	}
}
