.adaptive {
	display: none;
}
.adaptiveMin {
	display: block;
}

@media screen and (min-width: 940px) {
	.adaptive {
		display: block;
	}
	.adaptiveMin {
		display: none;
	}
}
