@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap);
body {
	/* background-color: #4c566f; */
	background-color: #ffffff;
	margin: 0;
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
	background: #000000 !important;
}
div.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters {
	background: transparent !important;
}

.App_container__1MQN3 {
	/* background-color: #fff; */
}

.NotFound_container__PScWX {
	font-size: 44px;
	text-align: center;
	font-weight: 400;
}

.NotFound_head__2dx73 {
	opacity: 0.1;
}

.ContentPattern_container__1WGCa {
	background-color: #f5f5f5;
	display: flex;
	justify-content: center;
	/* margin: 0 0 40px; */
	/* border: 1px solid #00ff00; */
	padding: 40px 0;
	width: 100%;
	box-sizing: border-box;
}

@media screen and (max-width: 820px) {
	.ContentPattern_container__1WGCa {
		padding: 20px 0;
	}
	.ContentPattern_content__3hEPQ {
		margin: 0 10px;
	}
}

.ContentPattern_content__3hEPQ {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* border: 1px solid #ff00ff; */
	max-width: 800px;
	width: 100%;
	word-break: break-word;
}

.Navbar_adaptive__gwri9 {
	display: none;
}
.Navbar_adaptiveMin__2mH_l {
	display: block;
}

@media screen and (min-width: 940px) {
	.Navbar_adaptive__gwri9 {
		display: block;
	}
	.Navbar_adaptiveMin__2mH_l {
		display: none;
	}
}

.Footer_container__st7Zp {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding: 40px 0;
}

.Footer_content__27StV {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	max-width: 900px;
	width: 100%;
	padding: 0 20px;
}

.Footer_contentBottom__1YCZW {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	max-width: 900px;
	width: 100%;
	padding: 0 20px;
	word-wrap: unset;
}

/* .contentBottom > .link {
	margin: 0 10px;
} */

.Footer_font__3je9h {
	font-family: 'TT Commons' !important;
}

.Footer_link__Xabb2 {
	line-height: 34px;
	padding: 0 30px;
	color: black !important;
	font-weight: 400;
}

@media screen and (max-width: 940px) {
	.Footer_container__st7Zp {
		padding: 40px 20px;
	}
	.Footer_content__27StV {
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
	}
	.Footer_link__Xabb2 {
		padding: 0;
		margin-bottom: 10px !important;
	}
	.Footer_wmImage__2oWr_ {
		padding-bottom: 10px;
	}
	.Footer_contentBottom__1YCZW {
		flex-direction: column;
		align-items: start;
		/* margin-top: 10px; */
	}
	.Footer_contentBottom__1YCZW > .Footer_link__Xabb2 {
		margin-left: 0;
	}
}

@media screen and (max-width: 1400px) {
	.Footer_container__st7Zp {
		padding-bottom: 60px;
	}
}

.Main_container__3Tx6w {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.Main_logo__3iPjA {
	max-height: 210px;
	transform: translateY(-14px);
}

.Main_text__2ghqa {
	margin-left: 20px;
	opacity: 0.8;
}

.Main_head__1G-VD {
	font-weight: 500;
	font-size: 24px;
	margin: 0;
	margin-bottom: 10px;
}

.Main_body__1Ck03 {
	font-size: 14px;
	margin: 0;
}

.Main_paymentImage__2QPhD {
	/* width: 40px; */
	height: 40px;
	margin-right: 36px;
	margin-left: 36px;
}
/* .paymentImage:last-child {
	margin-right: 0;
} */

.Main_paymentContainer__3iG-k {
	margin: 0 auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
}

.Main_paymentContainerImages__D9xxT,
.Main_paymentContainerImagesReverse__1L4IT {
	display: flex;
	flex-direction: row;
	text-align: center;
}

.Main_foregroundTickerPayments__1vSrw {
	background: linear-gradient(
		90deg,
		rgba(245, 245, 245, 1) 0%,
		rgba(245, 245, 245, 0) 10%,
		rgba(245, 245, 245, 0) 90%,
		rgba(245, 245, 245, 1) 100%
	);
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.Main_tickerPayments__34Emo {
	display: flex;
	justify-content: center;
	position: relative;
	width: 100%;
	background-color: #f5f5f5;
	/* position: relative; */
}

.Main_paymentContainerImages__D9xxT {
	animation: Main_ticker__1wYY9 infinite linear;
	margin-bottom: 0px;
}
.Main_paymentContainerImagesReverse__1L4IT {
	animation: Main_tickerReverse__1ydiH 14s infinite linear;
	-webkit-filter: grayscale(100%);
	        filter: grayscale(100%);
	opacity: 0.4;
}

.Main_textContainer__1YqsP {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 60px;
	margin-bottom: 60px;
}

.Main_title__1S8vA {
	font-size: 30px;
}
.Main_spanTitle__31Oda {
	font-size: 90px;
	line-height: 110px;
}

.Main_logoContainer__2x2ID > img {
	max-width: 900px;
	margin: 40px 0 100px;
	width: 100%;
}

.Main_logoDesctop__P2dd2 {
	display: flex;
}
.Main_logoMobile__1euHU {
	display: none;
	padding: 0 48px;
	box-sizing: border-box;
}

@media screen and (max-width: 800px) {
	.Main_logoMobile__1euHU {
		display: flex;
	}
	.Main_logoDesctop__P2dd2 {
		display: none;
	}
	.Main_centralText__6REXU {
		text-align: center;
	}
	.Main_textContainer__1YqsP {
		flex-direction: column;
	}
	.Main_logo__3iPjA {
		max-height: none;
		width: 70%;
		margin-bottom: 20px;
	}
	.Main_title__1S8vA {
		font-size: 30px;
	}
	.Main_spanTitle__31Oda {
		font-size: 70px;
		line-height: 70px;
	}
}

@media screen and (max-width: 440px) {
	.Main_spanTitle__31Oda {
		font-size: 50px;
	}
}

@keyframes Main_ticker__1wYY9 {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-25%);
	}
}

@keyframes Main_tickerReverse__1ydiH {
	from {
		transform: translateX(0%);
	}
	to {
		transform: translateX(25%);
	}
}

.Shop_categorys__U2X8Q {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.Shop_pathName__3Yv5W {
	font-size: 26px;
	font-weight: 700;
	margin: 10px 0;
	padding: 0 20px;
	text-transform: uppercase;
	color: #3c4858;
	margin-bottom: 30px;
}
/* 
@media screen and (max-width: 820px) {
	.pathName {
		text-align: center;
	}
} */

@media screen and (max-width: 800px) {
	.Shop_pathName__3Yv5W {
		padding: 0;
	}
	.Shop_productPaper__zhQge {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.Shop_listPrimary__34Y34 {
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 500;
	color: #3c4858;
}

.Shop_listSecondary__IjaYv {
	font-family: 'Roboto';
	font-size: 16px;
	color: #3c4858;
}

.Shop_colored__3zF5J {
	color: #2ecc71;
	font-weight: 500;
}

.Shop_notColored__3tGFe {
	color: #e74c3c;
	font-weight: 500;
}

.Shop_shadow__2jIS2 {
	border-radius: 8px !important;
	overflow: hidden;
}

.Shop_shadow__2jIS2:hover {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Shop_productPaper__zhQge {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	margin-bottom: 10px;
	/* word-break: break-all; */
}

.Shop_productName__3PG6i {
	font-size: 18px;
	font-weight: 700;
	margin: 0 0 20px;
	padding: 0;
	text-transform: uppercase;
	color: #3c4858;
}

.Shop_productHeader__Dwl58 {
	display: flex;
	width: 100%;
}

.Shop_productMainInfo__QKRzu {
	display: flex;
	flex-direction: column;
	font-size: 15px;
}

.Shop_productMainInfoName__3-tww {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 20px;
}

.Shop_productMainInfoDesc__1zs4o {
	margin-bottom: 20px;
}

.Shop_productCard__3D4Nd {
	display: flex;
	flex-direction: column;
	margin: 0 20px 20px 0;
}

.Shop_productPrice__1XV4u {
	font-size: 40px;
	font-weight: 400;
	color: #3c4858;
	text-align: center;
	margin: 20px 0 10px;
}

.Shop_productImg__2qwGH {
	width: 100%;
	margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
	.Shop_productHeader__Dwl58 {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.Shop_productCard__3D4Nd {
		width: 100%;
		margin: 0 0 20px;
	}
}

.Shop_sale__2Jn48,
.Shop_new__23XYs,
.Shop_top__21mnp {
	position: absolute;
	z-index: 1;
	padding: 2px 6px;
	font-size: 12px;
	transform: translateY(7px);
	color: #ffffff;
}

.Shop_sale__2Jn48::after,
.Shop_new__23XYs::after,
.Shop_top__21mnp::after {
	position: absolute;
	content: '';
	z-index: 2;
	border: 8.8px solid transparent;
	border-left: 8px solid;
	transform: translateY(-1.9px) translateX(6px);
}

.Shop_saleBig__3WQN-,
.Shop_newBig__2SH7B,
.Shop_topBig__2plXO {
	position: absolute;
	z-index: 1;
	padding: 2px 6px;
	font-size: 16px;
	transform: translateY(7px);
	color: #ffffff;
}

.Shop_saleBig__3WQN-::after,
.Shop_newBig__2SH7B::after,
.Shop_topBig__2plXO::after {
	position: absolute;
	content: '';
	z-index: 2;
	border: 11.2px solid transparent;
	border-left: 14px solid;
	transform: translateY(-2px) translateX(6px);
}

.Shop_new__23XYs,
.Shop_newBig__2SH7B {
	background-color: #2ecc71;
}
.Shop_new__23XYs::after,
.Shop_newBig__2SH7B::after {
	border-left-color: #2ecc71;
}

.Shop_sale__2Jn48,
.Shop_saleBig__3WQN- {
	background-color: #e74c3c;
}
.Shop_sale__2Jn48::after,
.Shop_saleBig__3WQN-::after {
	border-left-color: #e74c3c;
}

.Shop_top__21mnp,
.Shop_topBig__2plXO {
	background-color: #f39c12;
}
.Shop_top__21mnp::after,
.Shop_topBig__2plXO::after {
	border-left-color: #f39c12;
}

.Calculator_container__2lEIc {
	padding: 14px;
	box-sizing: border-box;
	width: 240px;
}

.Calculator_textboxNumber__1QTZd {
	width: 49%;
}

.Calculator_buyBtn__2Pfue {
	width: 100%;
}

@media screen and (max-width: 600px) {
	.Calculator_container__2lEIc {
		width: 100%;
	}
}

.Guarantee_container__3FTtt {
	font-family: 'Roboto';
	min-width: 210px;
	margin-bottom: 8px;
}

.Guarantee_item__24v91 {
	display: flex;
	align-items: center;
	font-size: 13px;
}

.Guarantee_icon__RKLR- {
	margin-right: 10px;
}

.Check_container__sRxvy {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	margin-bottom: 10px;
}

.Check_table-header__6BggU,
.Check_table-content__arBo0 {
	table-layout: fixed;
	background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
	border-radius: 12px;
}
.Check_tbl-content__3StCY {
	/* height: 300px; */
	overflow-x: auto;
	margin-top: 0px;
	border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.Check_th__IweF1 {
	width: 100%;
	padding: 15px;
	text-align: center;
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	border-bottom: solid 1px rgba(255, 255, 255, 0.1);
	text-transform: uppercase;
}
.Check_td__1KAgr {
	padding: 15px;
	text-align: left;
	vertical-align: middle;
	font-weight: 400;
	font-size: 16px;
	color: #fff;
	border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.Check_textContainer__14cCr {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.Check_logo__yI90P {
	max-height: 80px;
	transform: translateY(-14px);
}

@media screen and (max-width: 800px) {
	.Check_textContainer__14cCr {
		flex-direction: column;
	}
	.Check_logo__yI90P {
		max-height: none;
		width: 70%;
		margin-bottom: 20px;
	}
}

.Contacts_container__2L0E8 {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	margin-bottom: 10px;
}

.Contacts_header__2MtAA {
	font-size: 18px;
	font-weight: 700;
	margin: 0 0 20px;
	padding: 0;
	text-transform: uppercase;
	color: #3c4858;
}

.About_container__vm32Q {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	margin-bottom: 10px;
}

.About_header__ejYEm {
	font-size: 18px;
	font-weight: 700;
	margin: 0 0 20px;
	padding: 0;
	text-transform: uppercase;
	color: #3c4858;
}

.BottomMenu_container__2Ef3L {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	margin-bottom: 10px;
}

.BottomMenu_header__2ZR4V {
	font-size: 18px;
	font-weight: 700;
	margin: 0 0 20px;
	padding: 0;
	text-transform: uppercase;
	color: #3c4858;
}

.Policy_container__3IXdH {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	margin-bottom: 10px;
}

.Policy_header__2CsS1 {
	font-size: 18px;
	font-weight: 700;
	margin: 0 0 20px;
	padding: 0;
	text-transform: uppercase;
	color: #3c4858;
}

.Offer_container__CL-D4 {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	margin-bottom: 10px;
}

.Offer_header__2h2y- {
	font-size: 18px;
	font-weight: 700;
	margin: 0 0 20px;
	padding: 0;
	text-transform: uppercase;
	color: #3c4858;
}

.Guarantee_container__3uYWd {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	margin-bottom: 10px;
}

.Guarantee_header__Pw-s3 {
	font-size: 18px;
	font-weight: 700;
	margin: 0 0 20px;
	padding: 0;
	text-transform: uppercase;
	color: #3c4858;
}

.Payment_container__2S3Vh {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	margin-bottom: 10px;
	overflow: hidden;
}

.Payment_header__2MOUa {
	font-size: 18px;
	font-weight: 700;
	margin: 0 0 20px;
	padding: 0;
	text-transform: uppercase;
	color: #3c4858;
}

.Payment_container__2S3Vh > div > p > span > img {
	max-width: 100%;
	height: 100%;
}

