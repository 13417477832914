.container {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	margin-bottom: 10px;
	overflow: hidden;
}

.header {
	font-size: 18px;
	font-weight: 700;
	margin: 0 0 20px;
	padding: 0;
	text-transform: uppercase;
	color: #3c4858;
}

.container > div > p > span > img {
	max-width: 100%;
	height: 100%;
}
