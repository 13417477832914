.container {
	background-color: #f5f5f5;
	display: flex;
	justify-content: center;
	/* margin: 0 0 40px; */
	/* border: 1px solid #00ff00; */
	padding: 40px 0;
	width: 100%;
	box-sizing: border-box;
}

@media screen and (max-width: 820px) {
	.container {
		padding: 20px 0;
	}
	.content {
		margin: 0 10px;
	}
}

.content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* border: 1px solid #ff00ff; */
	max-width: 800px;
	width: 100%;
	word-break: break-word;
}
