.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding: 40px 0;
}

.content {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	max-width: 900px;
	width: 100%;
	padding: 0 20px;
}

.contentBottom {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	max-width: 900px;
	width: 100%;
	padding: 0 20px;
	word-wrap: unset;
}

/* .contentBottom > .link {
	margin: 0 10px;
} */

.font {
	font-family: 'TT Commons' !important;
}

.link {
	line-height: 34px;
	padding: 0 30px;
	color: black !important;
	font-weight: 400;
}

@media screen and (max-width: 940px) {
	.container {
		padding: 40px 20px;
	}
	.content {
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
	}
	.link {
		padding: 0;
		margin-bottom: 10px !important;
	}
	.wmImage {
		padding-bottom: 10px;
	}
	.contentBottom {
		flex-direction: column;
		align-items: start;
		/* margin-top: 10px; */
	}
	.contentBottom > .link {
		margin-left: 0;
	}
}

@media screen and (max-width: 1400px) {
	.container {
		padding-bottom: 60px;
	}
}
