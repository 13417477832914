.container {
	font-family: 'Roboto';
	min-width: 210px;
	margin-bottom: 8px;
}

.item {
	display: flex;
	align-items: center;
	font-size: 13px;
}

.icon {
	margin-right: 10px;
}
