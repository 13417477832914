.categorys {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.pathName {
	font-size: 26px;
	font-weight: 700;
	margin: 10px 0;
	padding: 0 20px;
	text-transform: uppercase;
	color: #3c4858;
	margin-bottom: 30px;
}
/* 
@media screen and (max-width: 820px) {
	.pathName {
		text-align: center;
	}
} */

@media screen and (max-width: 800px) {
	.pathName {
		padding: 0;
	}
	.productPaper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.listPrimary {
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 500;
	color: #3c4858;
}

.listSecondary {
	font-family: 'Roboto';
	font-size: 16px;
	color: #3c4858;
}

.colored {
	color: #2ecc71;
	font-weight: 500;
}

.notColored {
	color: #e74c3c;
	font-weight: 500;
}

.shadow {
	border-radius: 8px !important;
	overflow: hidden;
}

.shadow:hover {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.productPaper {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	margin-bottom: 10px;
	/* word-break: break-all; */
}

.productName {
	font-size: 18px;
	font-weight: 700;
	margin: 0 0 20px;
	padding: 0;
	text-transform: uppercase;
	color: #3c4858;
}

.productHeader {
	display: flex;
	width: 100%;
}

.productMainInfo {
	display: flex;
	flex-direction: column;
	font-size: 15px;
}

.productMainInfoName {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 20px;
}

.productMainInfoDesc {
	margin-bottom: 20px;
}

.productCard {
	display: flex;
	flex-direction: column;
	margin: 0 20px 20px 0;
}

.productPrice {
	font-size: 40px;
	font-weight: 400;
	color: #3c4858;
	text-align: center;
	margin: 20px 0 10px;
}

.productImg {
	width: 100%;
	margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
	.productHeader {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.productCard {
		width: 100%;
		margin: 0 0 20px;
	}
}

.sale,
.new,
.top {
	position: absolute;
	z-index: 1;
	padding: 2px 6px;
	font-size: 12px;
	transform: translateY(7px);
	color: #ffffff;
}

.sale::after,
.new::after,
.top::after {
	position: absolute;
	content: '';
	z-index: 2;
	border: 8.8px solid transparent;
	border-left: 8px solid;
	transform: translateY(-1.9px) translateX(6px);
}

.saleBig,
.newBig,
.topBig {
	position: absolute;
	z-index: 1;
	padding: 2px 6px;
	font-size: 16px;
	transform: translateY(7px);
	color: #ffffff;
}

.saleBig::after,
.newBig::after,
.topBig::after {
	position: absolute;
	content: '';
	z-index: 2;
	border: 11.2px solid transparent;
	border-left: 14px solid;
	transform: translateY(-2px) translateX(6px);
}

.new,
.newBig {
	background-color: #2ecc71;
}
.new::after,
.newBig::after {
	border-left-color: #2ecc71;
}

.sale,
.saleBig {
	background-color: #e74c3c;
}
.sale::after,
.saleBig::after {
	border-left-color: #e74c3c;
}

.top,
.topBig {
	background-color: #f39c12;
}
.top::after,
.topBig::after {
	border-left-color: #f39c12;
}
