.container {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.logo {
	max-height: 210px;
	transform: translateY(-14px);
}

.text {
	margin-left: 20px;
	opacity: 0.8;
}

.head {
	font-weight: 500;
	font-size: 24px;
	margin: 0;
	margin-bottom: 10px;
}

.body {
	font-size: 14px;
	margin: 0;
}

.paymentImage {
	/* width: 40px; */
	height: 40px;
	margin-right: 36px;
	margin-left: 36px;
}
/* .paymentImage:last-child {
	margin-right: 0;
} */

.paymentContainer {
	margin: 0 auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
}

.paymentContainerImages,
.paymentContainerImagesReverse {
	display: flex;
	flex-direction: row;
	text-align: center;
}

.foregroundTickerPayments {
	background: linear-gradient(
		90deg,
		rgba(245, 245, 245, 1) 0%,
		rgba(245, 245, 245, 0) 10%,
		rgba(245, 245, 245, 0) 90%,
		rgba(245, 245, 245, 1) 100%
	);
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.tickerPayments {
	display: flex;
	justify-content: center;
	position: relative;
	width: 100%;
	background-color: #f5f5f5;
	/* position: relative; */
}

.paymentContainerImages {
	animation: ticker infinite linear;
	margin-bottom: 0px;
}
.paymentContainerImagesReverse {
	animation: tickerReverse 14s infinite linear;
	filter: grayscale(100%);
	opacity: 0.4;
}

.textContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 60px;
	margin-bottom: 60px;
}

.title {
	font-size: 30px;
}
.spanTitle {
	font-size: 90px;
	line-height: 110px;
}

.logoContainer > img {
	max-width: 900px;
	margin: 40px 0 100px;
	width: 100%;
}

.logoDesctop {
	display: flex;
}
.logoMobile {
	display: none;
	padding: 0 48px;
	box-sizing: border-box;
}

@media screen and (max-width: 800px) {
	.logoMobile {
		display: flex;
	}
	.logoDesctop {
		display: none;
	}
	.centralText {
		text-align: center;
	}
	.textContainer {
		flex-direction: column;
	}
	.logo {
		max-height: none;
		width: 70%;
		margin-bottom: 20px;
	}
	.title {
		font-size: 30px;
	}
	.spanTitle {
		font-size: 70px;
		line-height: 70px;
	}
}

@media screen and (max-width: 440px) {
	.spanTitle {
		font-size: 50px;
	}
}

@keyframes ticker {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-25%);
	}
}

@keyframes tickerReverse {
	from {
		transform: translateX(0%);
	}
	to {
		transform: translateX(25%);
	}
}
