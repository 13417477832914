.container {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 20px;
	margin-bottom: 10px;
}

.table-header,
.table-content {
	table-layout: fixed;
	background: -webkit-linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
	background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
	border-radius: 12px;
}
.tbl-content {
	/* height: 300px; */
	overflow-x: auto;
	margin-top: 0px;
	border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.th {
	width: 100%;
	padding: 15px;
	text-align: center;
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	border-bottom: solid 1px rgba(255, 255, 255, 0.1);
	text-transform: uppercase;
}
.td {
	padding: 15px;
	text-align: left;
	vertical-align: middle;
	font-weight: 400;
	font-size: 16px;
	color: #fff;
	border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.textContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.logo {
	max-height: 80px;
	transform: translateY(-14px);
}

@media screen and (max-width: 800px) {
	.textContainer {
		flex-direction: column;
	}
	.logo {
		max-height: none;
		width: 70%;
		margin-bottom: 20px;
	}
}
